import { LitElement, css, html } from 'lit';

class AtomitLogo extends LitElement {
    static get styles(){
        return css`div {
            height: 3px;
            width: 3px;
            border-radius: 50%;
            background-color: #FF2020;
            display: inline-block;
            vertical-align: middle;
        }`;
    }
    render() { return html`ATOM<div></div>IT.dk`; }
}

customElements.define('atomit-logo', AtomitLogo); 
