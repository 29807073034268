import { LitElement, html, css } from 'lit';

class CookieConsent extends LitElement {

    render() {
        return !(localStorage.getItem('cookieconsent') || false)
            ? html`
               <link rel="stylesheet" href="./bootstrap/css/bootstrap.min.css">
               <div class="navbar navbar-dark navbar-fixed-bottom bg-dark">
                 <div class="text-light">
                 This website is using cookies for 
                 <a href="https://consent.guide/google-analytics-cookie-consent/">Google Analytics</a> only<br/>
                 <button type="button" class="btn btn-success" @click=${this.consent}>Allow</button>
                 <button type="button" class="btn btn-danger" @click=${this.disconsent}>Disallow</button>
                 </div>
               </div>`
            : '';
    }

    consent() {
        localStorage.setItem('cookieconsent', true);
        window.location.reload(false);
    }

    disconsent() {
        localStorage.setItem('cookieconsent', false);
        window.location.reload(false);
    }
}

customElements.define('cookie-consent', CookieConsent); 
