import { LitElement, html } from 'lit';
import { unsafeHTML       } from 'lit/directives/unsafe-html.js';
var showdown  = require('showdown');
showdown.setOption('tables', true);
var converter = new showdown.Converter();


class MainContent extends LitElement {
    createRenderRoot() {
        return this;
    }
    
    static get properties() {
        return {
            url:     { type: String }, // Current URL
            menu:    { type: String }, // Menu compopnent to listen to
            content: { type: String },
        };
    }

    constructor() {
        super();
        this.url     = '';
        this.content = '';
        this.menu    = '';
    }

    connectedCallback() {
        super.connectedCallback();
        document.querySelector(`#${this.menu}`).addEventListener('newURL', (e) => this.handleURL(e));
        const q = window.location.search.match(new RegExp(`^\\?${this.menu}=([^&]*)`));
        if (q) this.url = q[1];
        this.handleURL(); // Load default
    }

    render() {
        return html`
        <br>
        ${unsafeHTML(this.content)}`;
    }

    async handleURL(e) {
        if (e && e.detail) this.url = e.detail;
        if (!this.url) return;
        window.ga('set', 'page', this.url);
        window.ga('send', 'pageview');
        let r  = await fetch(this.url);
        let md = await r.text();
        let c  = converter.makeHtml(md);
        this.content = c;
    }
}

customElements.define('main-content', MainContent); 
