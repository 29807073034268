import { LitElement, html } from 'lit';

var operations = [{ r: (a,b) => 0  ,         t: 'Mix'            },
                  { r: (a,b) => a+b, o: '+', t: 'Addition'       },
                  { r: (a,b) => a-b, o: '-', t: 'Subtraction'    },
                  { r: (a,b) => a*b, o: '*', t: 'Multiplication' },
                  { r: (a,b) => a/b, o: '/', t: 'Division'       },
                 ];

class MathContent extends LitElement {
    createRenderRoot() {
        return this;
    }

    static get properties() {
        return {
            points:    { type: Number },  // Points (total)
            diff:      { type: Number },  // Points added for correct answer
            level:     { type: Number },  // Exercise level
            operation: { type: Object },  // Exercise Operation 
            exercises: { type: Array  },  // Exercises
            count:     { type: Number },  // Correct answers
        };
    }
    
    constructor() {
        super();
        this.points    = 0;
        this.diff      = 0;
        this.level     = 1;
        this.operation = operations[0]; // Mix
        this.count     = 0;
        this.start     = Date.now();
    }

    firstUpdated() {
        this.getExercises(this.operation);
    }        
    
    render() {
        return html`
        <div class="btn-group" role="group" title="Choose operation">
          ${operations.map(o => html`<button type="button" class="btn btn-outline-dark btn-sm ${this.operation.t == o.t ? 'active' : ''}" @click=${e => this.getExercises(o)}>${o.t}</button>`)}
        </div>
        <div class="btn-group" role="group" title="Increase level">
          <button type="button" class="btn btn-outline-dark btn-sm" @click=${e => { this.level++; this.getExercises(this.operation); }}>Level Up</button>
        </div>
        <table class="table">
          <tr><th colspan="2">${this.operation.t} (level ${this.level})</th></tr>
          ${this.exercises?.map(x => html`<tr><td>${x.a} ${x.o.o} ${x.b}</td><td>= <input type="number" id=${x.id} @keyup=${this.checkResult}/></td></td>`)}
        </table>
        Points: ${this.points} (${this.diff})
        ${this.count == 10 ? html`<br/><h1>HURRAY!!!!!</h1>` : ''}`;
    }

    checkResult(e) {
        var elem = e.target;
        var r = elem.value || '0';
        var x = this.exercises[elem.id];
        if (r == x.o.r(x.a, x.b)) {
            elem.style.backgroundColor = '#f0fff0';
            elem.disabled = true;
            var now = Date.now();
            this.diff    = Math.floor(50000 / (now + 1 - this.start));
            this.points += this.diff;
            this.start   = now;
            this.count  += 1;
        } else {
            elem.style.backgroundColor = '#fff0f0';
        }
    }
    
    getExercises(op){
        const l = document.querySelector('math-content').querySelectorAll('input');
        l && Array.from(l).map(x => {x.value = ''; x.disabled = false; x.style.backgroundColor=''; });
        this.count = 0;
        this.operation = op;
        this.exercises = [];
        for (var i = 0; i < 10; i++) {
            var o = op.t != 'Mix' ? op : operations[1 + Math.floor(Math.random() * (operations.length-1))];
            var a = Math.floor(Math.random() * (8 + this.level)) + 1;
            var b = Math.floor(Math.random() * (8 + this.level)) + 1;
            a += (o.o === '-' ? b : 0); // No negative results in minus ops
            a *= (o.o === '/' ? b : 1); // Division is integer resuĺts
            this.exercises.push({id: i, a: a, b: b, o: o });
        }
    }
}

customElements.define('math-content', MathContent); 
