import { LitElement, css, html } from 'lit';

class TopMenu extends LitElement {
    static get styles() {
        return css`
        a { cursor: pointer; }`;
    }

    static get properties() {
        return {
            path:  { type: String },
            id:    { type: String },
            url:   { type: String },
            items: { type: Array  },
        };
    }

    constructor() {
        super();
        this.prev  = '';
        this.url   = '';
        this.path  = '';
        this.id    = '';
        this.items = [];
    }

    async connectedCallback(){
        super.connectedCallback();
        if (!this.url) return;
        let items = await fetch(this.url);
        this.items = await items.json();
    }
        
    render() {
        return html`
        <link rel="stylesheet" href="./bootstrap/css/bootstrap.min.css">
        <nav class="navbar navbar-dark navbar-expand-sm sticky-top bg-dark">
          <a class="navbar-brand align-self-start" href="/"><slot name="brand"></slot></a>
 
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            ${this.items && this.items.map(item => { let title = item.title.replace(/_/g, ' ').replace(/.md$/, '');
            return item.title == 'index.md' ? html`` : html`
            <li class="nav-item">
            <a class="nav-link" id="${item.path||item.title}" title="by ${item.author||''} / ${item.timestamp||''}" @click="${this.handleClick}">
                ${title}
              </a>
            </li>`;})}
            <li class="nav-item">
              <slot></slot>
          </ul>
        </nav>`; }

    handleClick(e) {
        // Get element clicked from event
        var item = e.target;

        // Set/reset active on new/prev item
        this.prev && this.prev.classList.remove('active');
        item.classList.add('active');
        this.prev = item;

        // Emit event of selected url to display
        let event = new CustomEvent('newURL', { detail: item.id });
        this.dispatchEvent(event);
        
        // Change the state of the url
        window.history.pushState({}, item, `?${this.id}=${item.id}`);
    }
}

customElements.define('top-menu', TopMenu); 
